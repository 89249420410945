enum ApiRoutes {
  LOG_IN = '/auth/sign-in',
  SIGN_UP = '/auth/sign-up',
  SIGN_UP_VERIFY_EMAIL = '/auth/verify-email/request-otp',
  SIGN_UP_VERIFY_OTP = '/auth/verify-email',
  REFRESH_ACCESS = '/auth/refresh-access-token',
  RESET_PASSWORD_REQUEST_OTP = '/auth/reset-password/request-otp',
  RESET_PASSWORD_VERIFY_OTP = '/auth/reset-password/verify-otp',
  RESET_PASSWORD = '/auth/reset-password',
  PROPERTY = '/property',
  APPLICATION = '/application',
  SHARE = '/share',
  ACCEPT = '/accept',
  REJECT = '/reject',
  REQUEST_EDITS = '/request-edits',
  PAY = '/pay',
  BULK_GET_TEMPLATE = '/application/bulk/template',
  BULK_UPLOAD_FILE = '/application/bulk',
  APPLICATION_COUNTER_STATUS = '/application/counts-by-status',
  APPLICATION_BUNDLE = '/application/bundles',
  PROPERTY_COUNTER_STATUS = '/property/counts-by-status',
  PROFILE = '/user/me',
  PLAID_IDENTITY_GET_TOKEN = '/plaid/identity-verification/link-token',
  PLAID_IDENTITY_SEND_TOKEN = '/plaid/identity-verification/link-complete',
  PLAID_BANK_INCOME_GET_TOKEN = '/plaid/bank-income-and-assets/link-token',
  PLAID_BANK_INCOME_SEND_TOKEN = '/plaid/bank-income-and-assets/link-complete',
  PLAID_PAYROLL_INCOME_GET_TOKEN = '/plaid/payroll-income-and-fraud-risk/link-token',
  PLAID_PAYROLL_INCOME_SEND_TOKEN = '/plaid/payroll-income-and-fraud-risk/link-complete',
  TRANS_UNION_IDENTITY_VERIFY = '/trans-union/identity-verify',
  TRANS_UNION_IDENTITY_EVALUATE = '/trans-union/identity-evaluate',
  SHAREABLE_IDENTITY_VERIFY = '/trans-union/sharable-identity-verify',
  SHAREABLE_IDENTITY_EVALUATE = '/trans-union/sharable-identity-evaluate',
  PAYSCORE_INITIALIZATION = '/payscore/initialize',
  SEND_CHECKS_LETTER = '/adverse',
  SUPPORT_SEND_QUESTION = '/support/question'
}

export { ApiRoutes };
