enum Colors {
  ERROR_COLOR = '#FB3748',
  WHITE = '#fff',
  DARK_BLUE = '#062236',
  THUNDER_CLOUD = '#687588',
  DARK_BLUE_GRADIENT = 'linear-gradient(to bottom, #062236, #212528)',
  DARK_KNIGHT = '#111827',
  DARK = '#1F2937',
  DISABLED = '#CACFD8',
  NORTH_SEA_BLUE = '#323b49',
  BUTTON_START = '#117950',
  BUTTON_REJECT = '#FB3748',
  STATUS_ACTIVE = '#6366F1',
  STATUS_INACTIVE = '#F09B9B',
  STATUS_COMPLETED = '#15803D',
  STATUS_PAID = '#0EA5E9',
  STATUS_PENDING = '#EA580C',
  STATUS_REJECT = '#6B7280',
  STATUS_CANCELED = '#EF4444',
  STATUS_EDIT_REQUEST = '#F43F5E',
  STATUS_NEW = '#2563EB',
  BLUE_REFLECTION = '#cbd5e0',
  BLACK = '#0E121B',
  START_BTN = '#22C55E',
  REJECT_BTN = '#DC2626',
  APPROVE = '#16A34A',
  LIGHT_ROSE = '#ffebec',
  BORDER_COLOR = '#F3F4F6',
  REPORT_BG = '#FAFAFA'
}

export { Colors };
