import {
  Checkbox as MuiCheckBox,
  FormGroup,
  FormControl,
  FormHelperText,
  FormControlLabel,
  Stack
} from '@mui/material';

import { ReactComponent as CheckedIcon } from 'components/sign-in-story/images/checkbox-checked.svg';
import { ReactComponent as UncheckedIcon } from 'components/sign-in-story/images/checkbox-unchecked.svg';

interface CheckboxProps {
  label: string;
  checked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  helperText?: React.ReactNode;
}

export function Checkbox({ label, checked, onChange, helperText }: CheckboxProps) {
  return (
    <FormControl component="fieldset">
      <FormGroup>
        <Stack flexDirection="row" alignItems="center">
          <FormControlLabel
            control={
              <MuiCheckBox
                checked={checked}
                onChange={onChange}
                icon={<UncheckedIcon />}
                checkedIcon={<CheckedIcon />}
              />
            }
            label={label}
          />
        </Stack>

        {helperText && (
          <FormHelperText sx={{ mt: '-5px', fontSize: '12px' }}>{helperText}</FormHelperText>
        )}
      </FormGroup>
    </FormControl>
  );
}
