enum CheckTypes {
  ASSETS = 'plaid-assets',
  // BANK_INCOME = 'plaid-bank-income', // replaced by payscore
  PAYROLL_INCOME = 'plaid-payroll-income',
  MONITOR = 'plaid-monitor',
  PLAID_IDV_SELFIE_CHECK = 'plaid-idv-selfie-check',
  PLAID_IDV_DOCUMENT_CHECK = 'plaid-idv-document-check',
  TRU_VISION = 'trans-union-tru-vision',
  SHAREABLE = 'trans-union-sharable',
  SHAREABLE_PART = 'trans-union-sharable-no-eviction',
  PAYSCORE = 'payscore-bank-income'
}

const { ASSETS, PAYROLL_INCOME, PAYSCORE, MONITOR, TRU_VISION, SHAREABLE, SHAREABLE_PART } =
  CheckTypes;

export {
  CheckTypes,
  ASSETS,
  PAYROLL_INCOME,
  PAYSCORE,
  MONITOR,
  TRU_VISION,
  SHAREABLE,
  SHAREABLE_PART
};
